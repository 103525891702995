import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from '../../../shared/auth/auth.service';
import {CustomizerService} from '../../../shared/services/customizer.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-launch-screen',
  templateUrl: './launch-screen.component.html',
  styleUrls: ['./launch-screen.component.scss']
})
export class LaunchScreenComponent implements OnInit, OnDestroy {
  platform: any;

  constructor(public authService: AuthService, private customizerService: CustomizerService, private modalService: NgbModal) {
  }

  async ngOnInit(): Promise<void> {
    const body = {'url': window.location.origin};
    // const body = {'url': 'https://staging-frontend.posdirect.com.au'};
    await this.authService.fetchPlatForm(body).then((resp: any) => {
      if (typeof resp['data'] !== 'string') {
        sessionStorage.setItem('platform', JSON.stringify(resp.data));
        this.platform = JSON.parse(sessionStorage.getItem('platform'));
        const root = document.documentElement;
        root.style.setProperty('--primary-color', resp.data.primaryColour);
        root.style.setProperty('--secondary-color', resp.data.secondaryColour);
      }
    })
    history.pushState(null, '', location.href);
    window.onpopstate = () => {
      history.go(1);
    }
  }


  ngOnDestroy(): void {
    this.modalService.dismissAll();
  }

}
