<section id="login" class="page-wrapper">
  <div class="row auth-height full-height-vh m-0">
    <div class="col-12 d-flex align-items-center justify-content-center">
      <div class="payment-container">
        <div class="header">
          <img src="assets/img/HeaderLogo.ee686e04b34072be3e83.svg" alt="BPOINT Logo" class="logo">
        </div>
        <div class="payment-details">
          <div class="row">
            <div class="col-md-12 col-12 col-sm-12 col-lg-12">
              <h5 class="font-small-3 font-weight-bold">You are paying</h5>
              <p>SEED PHARMACEUTICALS AUSTRALIA PTY LTD</p>
            </div>
            <div class="col-md-12 col-12 col-sm-12 col-lg-12 mt-1">
              <h5 class="font-small-3 font-weight-bold">Biller Code</h5>
              <p>2256857 - Online Payment - Seed Pharmaceuticals</p>
            </div>
            <div class="col-md-12 col-12 col-sm-12 col-lg-12 mt-1">
              <h5 class="font-small-3 font-weight-bold">Reference 1</h5>
              <p>string</p>
            </div>
            <div class="col-md-12 col-12 col-sm-12 col-lg-12 mt-1">
              <h5 class="font-small-3 font-weight-bold">Amount</h5>
              <p>AUD 1.00</p>
            </div>
            <div class="col-md-12 col-12 col-sm-12 col-lg-12 mt-1">
              <h5 class="font-small-3 font-weight-bold">Accepted Cards</h5>
              <img src="assets/img/mastercard.png" (click)="selectedPaymentCard.logo = 'assets/img/mastercard.png'; selectedPaymentCard.name = 'master card'" alt="Mastercard" class="card-logo cursor-pointer">
              <img src="assets/img/visacard.png" (click)="selectedPaymentCard.logo = 'assets/img/visacard.png'; selectedPaymentCard.name = 'visa card'" alt="Visa" class="card-logo cursor-pointer">
            </div>
          </div>
        </div>
        <div class="card-info">
          <form [formGroup]="cardDetailsForm" (ngSubmit)="addPaymentDetails()">
            <div class="row">
              <div class="col-md-5 col-lg-5 col-sm-12 col-12">
                <label class="font-small-3 font-weight-bold text-capitalize">Card number</label>
                <div class="input-group">
                  <input type="text" placeholder="5123456789012346" class="form-control" maxlength="16" formControlName="cardNumber">
                  <div class="input-group-prepend">
                        <span class="input-group-text cursor-pointer"> <img [src]="selectedPaymentCard.logo" [alt]="selectedPaymentCard.name" class="selected-logo"></span>
                  </div>
                </div>
                <small class="text-danger" *ngIf="submitted && cardDetailsForm.controls['cardNumber'].invalid">Enter card number</small>
              </div>
            </div>
            <div class="row mt-1">
              <div class="col-md-6 col-md-3 col-sm-12 col-12">
                <label class="font-small-3 font-weight-bold text-capitalize">Expiry date</label>
                <div class="d-flex">
                  <input type="text" class="form-control" formControlName="startDate" placeholder="99">
                  <input type="text" class="form-control ml-2" formControlName="endDate" placeholder="24">
                </div>
                <small class="text-danger" *ngIf="submitted && (cardDetailsForm.controls['endDate'].invalid || cardDetailsForm.controls['startDate'].invalid)">Enter card expiry date
                </small>
              </div>
              <div class="col-md-6 col-lg-6 col-sm-12 col-12">
                <label class="font-small-3 font-weight-bold text-capitalize">CVN</label>
                <input type="text" id="cvn" formControlName="cvn" placeholder="987" class="form-control">
                <small class="text-danger" *ngIf="submitted && cardDetailsForm.controls['cvn'].invalid">Enter cvn number
                </small>
              </div>
            </div>
            <button type="submit" class="text-dark btn btn-large next">Next<i class="ft-chevron-right"></i></button>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
