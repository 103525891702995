import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {

  selectedPaymentCard = {
    logo: 'assets/img/mastercard.png', name: 'master card'
  }
  cardDetailsForm = new FormGroup({
    cardNumber: new FormControl('', [Validators.required, Validators.pattern('^\\d{16}$')]),
    startDate: new FormControl('', [Validators.required]),
    endDate: new FormControl('', [Validators.required]),
    cvn: new FormControl('', [Validators.required]),
  });
  submitted = false;

  constructor() {
  }

  ngOnInit(): void {
  }

  addPaymentDetails() {
    this.submitted = true;
    if (this.cardDetailsForm.invalid) {
      return;
    }
  }
}
