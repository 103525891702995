<!--Login Page Starts-->
<section id="login" class="page-wrapper">
  <div class="row auth-height full-height-vh m-0">
    <div class="col-12 d-flex align-items-center justify-content-center">
      <div class="card overflow-hidden">
        <div class="card-content">
          <div class="card-body auth-img">
            <div class="row">
              <div class="col-12 col-md-12 d-flex justify-content-center align-items-center auth-img-bg p-2">
                <img src="{{this.platform.logo}}" width="auto" height="70" alt="logo">
              </div>
            </div>
            <div class="row m-0">
              <div class="col-12 d-flex justify-content-center align-items-center auth-img-bg p-2">
                <h3 class="text-center">Welcome to <br>{{this.platform?.name}}</h3>
              </div>
            </div>
            <div class="row m-0">
              <div class="col-12 d-flex justify-content-center align-items-center auth-img-bg p-2">
                <h4 class="text-center title-text">Login</h4>
              </div>
            </div>
            <div class="row m-0">
              <div class="col-12 d-flex justify-content-center align-items-center auth-img-bg p-2">
                <a class="btn btn-md primary-btn" [routerLink]="['../login']"><i class="ft-mail mr-1"></i>Email</a>
                <span class="ml-2 title-text">OR</span>
                <a [routerLink]="['../customer-login']" class="btn btn-md secondary-btn float-right mx-2"><i
                  class="ft-phone mr-1"></i>Phone</a>
              </div>
            </div>
            <div class="row m-0">
              <div class="col-12 px-1 py-1">
                <div class="text-center">
                  <p>Don't have an account?
                    <a class="btn-link font-weight-bold" [routerLink]="['../register']" routerLinkActive="active">Register</a></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--Login Page Ends-->
